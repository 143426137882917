import { CircularProgress } from "@material-ui/core"

import { MonitoringProfileSettings } from "src/components/SettingsProfiles/MonitoringProfileSettings"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { IProfile } from "src/data/homeProfiles/types/homeProfilesTypes"
import { useFetchOrganization } from "src/data/organizations/queries/organizationQueries"

export function EmbeddedSettingsProfile({
  initialProfile,
}: {
  initialProfile: IProfile
}) {
  const fetchProfile = useFetchProfile({
    id: initialProfile.id,
    options: { initialData: initialProfile },
  })
  const profile = fetchProfile.data
  const { data: org } = useFetchOrganization({
    id: initialProfile.organization_id,
  })

  if (!org) {
    return <CircularProgress size={30} />
  }
  if (!profile) {
    return <CircularProgress size={30} />
  }

  return (
    <MonitoringProfileSettings
      profileResponse={profile}
      organizationResponse={org}
    />
  )
}
