import styled from "styled-components"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"

export function CallAssistUpgradePopupButton() {
  const { t, langKeys } = useTranslate()

  return (
    <StyledUpgradeIconButton
      context="call-assist"
      buttonText={t(langKeys.learn_more)}
      text={t(langKeys.settings_noise_alerts_call_assist_trained_expert)}
      link={Routes.CallAssistActivate.location().pathname}
    />
  )
}

const StyledUpgradeIconButton = styled(UpgradeIconButton)`
  display: inline;
`
