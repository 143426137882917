import { useState } from "react"
import styled from "styled-components"

import { Divider } from "@material-ui/core"

import { CallAssistUpgradePopupButton } from "src/components/SettingsProfiles/CallAssistUpgradePopupButton"
import { NoiseAlertDialog } from "src/components/SettingsProfiles/NoiseAlertDialog"
import { breakpoint } from "src/constants/breakpoints"
import {
  usePostTrackNoiseSettingManagedEvent,
  usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent,
  variantToAlertLevel,
} from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { IOrganizationResponse } from "src/data/organizations/types/organizationTypes"
import { selectSettings } from "src/data/profileSettings/noiseAlertSettings"
import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { MInfo } from "src/ui/Info/MInfo"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MLink } from "src/ui/Link/MLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoiseAlertSettingCard({
  variant,
  profileResponse,
  organizationResponse,
}: {
  variant: TNoiseAlertVariant
  profileResponse: IProfileResponse
  organizationResponse: IOrganizationResponse
}) {
  const { t, langKeys } = useTranslate()

  const [showDialog, setShowDialog] = useState(false)

  const manageTrackingEvent = usePostTrackNoiseSettingManagedEvent()

  const callAssist = useFeatureAvailability({ feature: "use_call_assist" })

  const settings = selectSettings(
    variant,
    organizationResponse,
    profileResponse
  )
  const showToolTip = variant === "first_alert"

  const showCallAssistUpgrade =
    variant === "third_alert" && !callAssist.available

  const showCallAssistPill = variant === "third_alert" && callAssist.available

  function openDialog() {
    setShowDialog(true)
  }

  function handleManageClick() {
    manageTrackingEvent.mutate({
      alert_level: variantToAlertLevel[variant],
    })
    openDialog()
  }

  return (
    <MCard boxShadow={false} border padding={spacing.L}>
      <NoiseAlertDialog
        variant={variant}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        settings={settings}
      />
      <Content>
        <HeaderContent>
          <MText variant="heading3">{title(variant, t)}</MText>
          <MButton variant="subtle" size="small" onClick={handleManageClick}>
            {t(langKeys.manage)}
          </MButton>
        </HeaderContent>

        <SubtitleText variant="body" color="secondary">
          {description(variant, t)}{" "}
          {showToolTip && (
            <MInfo
              wrapperProps={{
                style: {
                  display: "inline",
                },
              }}
              interactive
              content={
                <>
                  <MText variant="bodyS">
                    {t(langKeys.settings_noise_alert_first_trigger)}
                  </MText>
                  <MLink href={Routes.Sensors.location().pathname}>
                    {t(langKeys.settings_noise_alerts_edit_duration)}
                  </MLink>
                </>
              }
            />
          )}
        </SubtitleText>

        <PillsContent>
          <LabelledOnOffPill
            label={t(langKeys.settings_noise_alerts_sms_title)}
            isOn={settings.smsEnabled}
          />
          <LabelledOnOffPill
            label={t(langKeys.settings_noise_alerts_autocall_title)}
            isOn={settings.autocallEnabled}
          />
          <LabelledOnOffPill
            label={t(langKeys.settings_noise_alerts_flash_and_sound_title)}
            isOn={settings.flashEnabled}
          />
          {showCallAssistPill && (
            <LabelledOnOffPill
              label={t(langKeys.call_assist)}
              isOn={settings.callAssistEnabled ?? false}
            />
          )}
        </PillsContent>
        {showCallAssistUpgrade && <UpgradeDisclaimer />}
      </Content>
    </MCard>
  )
}

function UpgradeDisclaimer() {
  const { t, langKeys } = useTranslate()

  const callAssistLearnMoreTrackingEvent =
    usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent()

  function handleLearnMoreClick() {
    callAssistLearnMoreTrackingEvent.mutate({
      context: "third_alert_card",
    })
  }

  return (
    <DisclaimerContainer>
      <Divider />
      <MText variant="subtitle">
        {t(langKeys.settings_noise_alerts_call_assist_trained_expert)}{" "}
        <CallAssistUpgradePopupButton />
      </MText>
      <MText variant="bodyS">
        {t(langKeys.settings_noise_alerts_call_assist_solve)}{" "}
        <ExternalLink
          beforeNav={handleLearnMoreClick}
          href="https://www.minut.com/product/minut-monitoring"
        >
          {t(langKeys.settings_noise_alerts_call_assist_learn_more)}
        </ExternalLink>
      </MText>
    </DisclaimerContainer>
  )
}

function LabelledOnOffPill({ label, isOn }: { label: string; isOn: boolean }) {
  const { t, langKeys } = useTranslate()
  return (
    <PillContainer>
      <MText variant={isOn ? "subtitle" : "body"}>{label}</MText>
      <MBadge color={isOn ? "info" : "neutral"}>
        {isOn ? t(langKeys.on) : t(langKeys.off)}
      </MBadge>
    </PillContainer>
  )
}

function title(variant: TNoiseAlertVariant, t: TTranslateFunction) {
  const titles: Record<TNoiseAlertVariant, string> = {
    first_alert: t(langKeys.settings_noise_alerts_first_alert_title),
    second_alert: t(langKeys.settings_noise_alerts_second_alert_title),
    third_alert: t(langKeys.settings_noise_alerts_third_alert_title),
  }

  return titles[variant]
}

function description(variant: TNoiseAlertVariant, t: TTranslateFunction) {
  const descriptions: Record<TNoiseAlertVariant, string> = {
    first_alert: t(langKeys.settings_noise_alerts_first_alert_description),
    second_alert: t(langKeys.settings_noise_alerts_second_alert_description),
    third_alert: t(langKeys.settings_noise_alerts_third_alert_description),
  }

  return descriptions[variant]
}

const DisclaimerContainer = styled.div`
  padding-top: ${spacing.M};
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PillsContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  flex-wrap: wrap;
`

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  padding-top: ${spacing.XS};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
  container-type: inline-size;
`

const SubtitleText = styled(MText)`
  padding-top: ${spacing.XS};
  padding-bottom: 0;

  @container (${breakpoint.mediumUp}) {
    padding-top: 0;
  }
`
